import { api } from './api';
import { dlg } from './dlg';
import { openLoginDlg } from './loginDlg';
import { PostTag } from './posting';
import { PostType, PostTypeMap } from './postingDlg';
import { openReplyDlg } from './replyDlg';
import { state } from './state';
import { UserPostingsDlg } from './userPostings';

function ViewPostingDlg(p: { t: IPosting }) {
	let el: HTMLDivElement;
	const l = p.t;

	let help_offered = l.type === PostType.help_offered;

	function edit_posting() {
		let can_edit = false;
		if (state.is_org_user && state.org.ref.id === l.org_ref?.id) {
			can_edit = true;
		}
		if (!can_edit) {
			const pin = prompt('Enter your pin number');
			if (pin) {
				if (pin == l.pin) {
					can_edit = true;
				} else {
					alert('Invalid pin code');
				}
			}
		}
		if (can_edit) {
			dlg.close(el);
			state.editPost(l.ref.id);
		}
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<div class="dlg-header center">
					<PostTag t={l.type} />
					<div class="grow" style={{ 'font-weight': 'bold' }}>
						{l.title}
					</div>
					{l.post_date?.toDateString()}
				</div>
				<div class="dlg-content" style={{ 'flex-grow': 1 }}>
					<div class="row">
						<label>Description</label>
						<div class="valuebox">{l.desc}</div>
					</div>
					{l.org && (
						<div class="row">
							<label>Organization</label>
							<div class="valuebox">{l.org.name}</div>
						</div>
					)}
					{(state.has_user || help_offered) && (
						<div class="row">
							<label>Name</label>
							<div class="valuebox">{l.name}</div>
						</div>
					)}
					{(state.has_user || help_offered) && (
						<div class="row">
							<label>Phone Number</label>
							<div class="valuebox">{l.phone}</div>
						</div>
					)}
					<div class="row">
						<label>Zip Code</label>
						<div class="valuebox">{l.zip_code}</div>
					</div>
					{l.replies.length && (
						<div class="row">
							<label>Actions Taken</label>
							<div class="flex column gap10">
								{l.replies.map((r) => (
									<div class="flex">
										<span class="grow">
											<b>
												{r.reply_date.toLocaleDateString()}:<b />
											</b>
											&nbsp;{r.info}
										</span>
										{(state.admin || state.user?.ref.id === r.user_ref?.id) && (
											<a onClick={(e) => openReplyDlg(l, r, false)}>edit</a>
										)}
									</div>
								))}
							</div>
						</div>
					)}
				</div>
				<div class="dlg-footer">
					<button
						onClick={(e) => {
							dlg.open(UserPostingsDlg, { phone: l.phone });
							dlg.close(el);
						}}
					>
						User History
					</button>
					<button onclick={edit_posting}>Edit</button>
					<span class="grow"></span>
					<button
						onClick={async (e) => {
							if (!state.has_user) {
								await openLoginDlg(null);
							}
							if (state.has_user) {
								openReplyDlg(l, null, true);
							}
						}}
					>
						Add Action
					</button>
					<button class="primary" onClick={(e) => dlg.close(el)}>
						Back
					</button>
				</div>
			</div>
		</div>
	);
}

export function openViewPostingDlg(t: IPosting): Promise<IPosting> {
	return dlg.open(ViewPostingDlg, { t });
}
